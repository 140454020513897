<template>
	<div v-if="show">
		<div class="item_line">
			<span class="titlename" style="font-size: 18px; color: #333; font-weight: bold">{{ form.name }}</span>
		</div>
		<div class="item_line">
			<span class="titlename">会议文件</span>
		</div>
		<div class="item_line" style="margin-left: 10px; width: 70%; justify-content: flex-end"
			v-show="!istz && roleid == 2">
			<el-upload class="upload-demo" :action="baseurl + '/api/login/upload'" multiple :limit="9"
				:on-success="uploadsus" :show-file-list="false">
				<el-button size="small" type="primary">点击上传</el-button>
			</el-upload>
		</div>
		<div style="width: 70%; margin-left: 10px">
			<div style="font-size: 16px; font-weight: bold">文件列表</div>
			<div v-for="(item, index) in list" style="margin-top: 10px; display: flex; align-items: center">
				<div style="
            flex: 5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          ">
					<a :href="item.fujian" target="_blank">附件：{{ item.fujiananem }}</a>
				</div>
				<div v-show="!istz && roleid == 2" style="
            flex: 1;
            display: flex;
            align-items: center;
            color: #d00;
            cursor: pointer;
          " @click="deletefiles(index)">
					<i class="el-icon-delete" style="color: #d00; font: 16px"></i>
					<div style="text-indent: initial">删除</div>
				</div>
			</div>
		</div>
		<div class="item_line" style="
        margin-left: 10px;
        width: 70%;
        justify-content: center;
        margin-top: 40px;
      " v-show="!istz && roleid == 2">
			<el-button type="primary" @click="save">确认保存</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			istz: {
				type: Boolean,
				default: false,
			},
			updataQx: {
				type: Number,
				default: 1,
			},
		},
		data() {
			return {
				baseurl: "",
				show: false,
				form: "",
				showselect: false,
				list: [],
				textarea: "",
				roleid: "",
			};
		},
		created() {
			this.roleid = this.updataQx;
			this.baseurl = this.$URL;
		},
		methods: {
			deletefiles(index) {
				this.list.splice(index, 1);
			},
			uploadsus(e) {
				console.log(e)
				let obj = {
					fujian: e.fullurl,
					fujiananem: e.file_name,
					id:e.id
				};
				this.list.push(obj);
			},
			save() {
				let ids = this.list
					.map((item) => {
						return item.id;
					})
					.join(",");
				this.$post({
					url: "/api/wymeeting/edit2",
					params: {
						wenjian:ids,
						id: this.form.id,
					},
				}).then((res) => {
					this.$message.success("操作成功");
					this.$emit("getinfo");
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.tapmenu {
		font-size: 16px;
		margin-right: 16px;
		cursor: pointer;
		padding-bottom: 5px;
	}

	.active_tap {
		color: #ec4a4a;
		border-bottom: 2px solid #ec4a4a;
	}

	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.titlename {
			margin-left: 10px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 85vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: "" !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .myinputicon {
		cursor: pointer;
	}

	::v-deep .myinputicon:hover {
		color: red !important;
	}
</style>